import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {Container, Typography, Box} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './SaleTimerBannerStyles';
import { withNamespaces, Trans } from 'react-i18next';
import phones from '../../assets/images/bf_phones.png';
import clsx  from 'clsx';

function SaleTimerBanner({
	t, 
	classes, 
	price, 
	planConfig,
	onClickBanner
}) {
	const _time = 1750; //moment('2023-11-17T09:00:00.000').diff(moment(), 'seconds');
	const [seconds, onUpdateSeconds] = useState(_time);
	let intervalId = -1;

	const convertSecondsToReadableString = (seconds) => {
		const _s = (seconds > 0) ? seconds : 0;
		const d = Math.floor(_s / (3600 * 24));
		const h = Math.floor(_s % (3600 * 24) / 3600);
		const m = Math.floor(_s % 3600 / 60);
		const s = Math.floor(_s % 60);

		return {d, h, m, s};
	};

	useEffect(() => {
		intervalId = setInterval(() => {
			onUpdateSeconds(prevTime => prevTime - 1);
		}, 1000);

		return () => clearInterval(intervalId);
	}, []);

	const dateArray = convertSecondsToReadableString(seconds);
	const _hours = dateArray.h;
	const _miuntes = dateArray.m;
	const _seconds = dateArray.s;

	return (
		<div className={clsx(classes.saleTimerBanner,{[classes[planConfig?.saleTimerBgClassName]]: planConfig?.saleTimerBgClassName})} onClick={onClickBanner}>
			<Container component="div" className={classes.saleTimerBannerContainer}>
				<Box display="flex" alignItems="center" className={classes.saleTimerBannerText}>
					<img src={phones} alt="" className={classes.saleTimerBannerPhones} />
					<span>
						{planConfig?.saleTimerTitle ? 
							<span>
								<span className={classes.textGreen}>{price}</span> <br/>{t(planConfig?.saleTimerTitle)}
							</span> : 
							planConfig?.saleTimerTitleArray ?
								<span>
									<span className={classes.textGreen}>{price}</span> <br/>
									{planConfig?.saleTimerTitleArray.map(p => <span key={p}>{t(p)}<br/></span>)}
								</span> :
								<Trans i18nKey="transHs">
									<span className={classes.textGreen}>{{transHsPrice: price}}</span> Holiday <br/>VPN Deal!
								</Trans>
						}
					</span>
				</Box>
				{seconds > 0 ?
					<Typography component="div" className={classes.saleTimerBannerClock}>
						<Box className={classes.saleTimerBannerClockItem}>
							<span>{(_hours < 10 ? '0': '') + _hours}</span>
							<span className={classes.saleTimerBannerClockItemText}>{t('HOUR' + (_hours > 1 ? 'S' : ''))}</span>
						</Box>
						<Box className={classes.saleTimerBannerClockItemSeparator}>:</Box>
						<Box className={classes.saleTimerBannerClockItem}>
							<span>{(_miuntes < 10 ? '0': '') + _miuntes}</span>
							<span className={classes.saleTimerBannerClockItemText}>{t('MINUTE' + (_miuntes > 1 ? 'S' : ''))}</span>
						</Box>
						<Box className={classes.saleTimerBannerClockItemSeparator}>:</Box>
						<Box className={classes.saleTimerBannerClockItem}>
							<span>{(_seconds < 10 ? '0': '') + _seconds}</span>
							<span className={classes.saleTimerBannerClockItemText}>{t('SECOND' + (_seconds > 1 ? 'S' : ''))}</span>
						</Box>
					</Typography>:null
				}
				<Typography component="div" className={classes.saleTimerBannerCTA}>
					{t('Get Deal')}
				</Typography>
			</Container>
		</div>
	);
}

SaleTimerBanner.propTypes = {
	classes: PropTypes.object,
	price: PropTypes.string,
	planConfig: PropTypes.object,
	onClickBanner: PropTypes.func,
	t: PropTypes.func
};

export default withStyles(useStyles)(withNamespaces()(SaleTimerBanner));