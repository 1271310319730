import React, { Component } from 'react';
import { PlanTypes, Saving } from '../../config/plan-types';
import { CURRENCY } from '../../config/main';
import { Container, Typography, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Scroll, { scroller } from 'react-scroll';
import { withNamespaces, Trans } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './HomeStyles';
import { getCouponMonthly, getCouponYearly2, getCouponByCode, resetCouponByCode, updateSentryProps } from '../../store/actions';
import utils from '../../services/utils';
import clsx  from 'clsx';
import queryString from 'query-string';

import Loader from '../../components/UI/Loader/Loader';
import Blocked from '../../components/UI/Blocked/Blocked';
import PlansSectionV2 from '../../components/PlansSection/PlansSection';
import PaymentSectionV2 from '../../components/V2/PaymentSectionV2/PaymentSectionV2';
import PlansSectionV3 from '../../components/V3/PlansSectionV3/PlansSectionV3';
import PaymentSectionV3 from '../../components/V3/PaymentSectionV3/PaymentSectionV3';
import FreemiumInterstitialV3 from '../../components/V3/FreemiumInterstitialV3/FreemiumInterstitialV3';
import AbandonmentModal from '../../components/AbandonmentModal/AbandonmentModalV2';
import ProxyDetectModal from '../../components/ProxyDetectModal/ProxyDetectModal';
import vpnOverviewLogo from '../../assets/images/vpnoverview-logo.png';
import AimtellBanner from '../../components/AimtellBanner/AimtellBanner';
import SaleTimerBanner from '../../components/SaleTimerBanner/SaleTimerBanner';

const { REACT_APP_URL } = process.env;
const scroll = Scroll.animateScroll;

class Home extends Component {
	constructor(props) {
		super(props);
		this.handleSelectPackage = this.handleSelectPackage.bind(this);
		this.handleScrollToPaymentSection = this.handleScrollToPaymentSection.bind(this);
		this.handleOpenAbandonmentModal = this.handleOpenAbandonmentModal.bind(this);
		this.handleCloseAbandonmentModal = this.handleCloseAbandonmentModal.bind(this);
		this.handleRefreshPage = this.handleRefreshPage.bind(this);
		this.initPackage = this.initPackage.bind(this);
		this.handleChangeFreemiumInterstitialStep = this.handleChangeFreemiumInterstitialStep.bind(this);
		this.handleToggleFreemiumModal = this.handleToggleFreemiumModal.bind(this);
		this.handleClickBanner = this.handleClickBanner.bind(this);

		this.state = {
			showAbandonmentModal : false,
			showFreemiumModal: false,
			freemiumInterstitialStep: 0
		};
	}

	componentDidMount() {
		const queryParams = queryString.parse(location.search, {arrayFormat: 'index'});
		const {planConfig = {}, couponMonthly, couponYearly2, onGetCouponMonthly, onGetCouponYearly2} = this.props;
		const _couponMonthly = queryParams.monthcoupon || planConfig?.couponMonthly || sessionStorage?.getItem('queryМonthCoupon');
		const _couponYearly2 = queryParams.biannualcoupon || planConfig?.couponYearly2 || sessionStorage?.getItem('queryBiannualCoupon');
		
		this.initPackage();

		if(!couponMonthly.code && _couponMonthly) {
			sessionStorage?.setItem('queryМonthCoupon', _couponMonthly);
			onGetCouponMonthly(_couponMonthly, PlanTypes.MONTHLY, []);
		}

		if(!couponYearly2.code && _couponYearly2) {
			sessionStorage?.setItem('queryBiannualCoupon', _couponYearly2);
			onGetCouponYearly2(_couponYearly2, PlanTypes.YEARLY2, []);
		}

		this.props.history.listen((location) => {
			if(location.action === 'POP') {
				this.initPackage();
			}
		});
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if(!this.props.upgradeMode && nextProps.upgradeMode || !this.props.packagesList.length && nextProps.packagesList.length) {
			this.initPackage(nextProps.upgradeMode, nextProps.packagesList);
		}
		if(nextProps.upgradeMode && !this.props.queryParams?.biannualcoupon && nextProps.queryParams?.biannualcoupon) {
			sessionStorage?.setItem('queryBiannualCoupon', nextProps.queryParams?.biannualcoupon);
			this.props.onGetCouponYearly2(nextProps.queryParams?.biannualcoupon, PlanTypes.YEARLY2, []);
		}
		if(nextProps.queryParams.plan) {
			this.handleScrollToPaymentSection();
		}
	}

	initPackage(updatedUpgradeMode = false, updatedPackagesList) {
		const {V3, planConfig = {}, queryParams, onSetSelectedPackage, upgradeMode, packagesList, sentryProps, onUpdateSentryProps} = this.props;
		const _upgradeMode = updatedUpgradeMode || upgradeMode;
		const _packagesList = updatedPackagesList || packagesList;
		const _yearly2Package = _packagesList.find(p => p.packageIdentifier === PlanTypes.YEARLY2) || {};
		let _selectedPackage = _packagesList.find(p => p.packageIdentifier === PlanTypes.YEARLY) || {};

		if((planConfig.showYearly2 || _upgradeMode && queryParams.active_vpn) && _yearly2Package.packageIdentifier) {
			_selectedPackage = _yearly2Package;
		}

		if(queryParams.plan) {
			const _redirectedPackage = _packagesList.find(p => p.packageIdentifier === queryParams.plan) || {};
			if(V3 && !planConfig.hideFree && queryParams.plan === PlanTypes.FREE) {
				this.handleChangeFreemiumInterstitialStep(2, planConfig.couponInterstitial, _redirectedPackage);
			}
			if(_redirectedPackage.packageIdentifier
				&& (_redirectedPackage.packageIdentifier !== PlanTypes.OVERQUOTA 
				&& (_redirectedPackage.packageIdentifier !== PlanTypes.YEARLY2 || _redirectedPackage.packageIdentifier == PlanTypes.YEARLY2 && (planConfig.showYearly2 || _upgradeMode)) 
				&& (planConfig.showOnlyYearly ? (_redirectedPackage.packageIdentifier === PlanTypes.YEARLY) : (((planConfig.hideFree && _redirectedPackage.packageIdentifier !== PlanTypes.FREE) || !planConfig.hideFree) && ((planConfig.hideMonthly && _redirectedPackage.packageIdentifier !== PlanTypes.MONTHLY) || !planConfig.hideMonthly))))
			) {
				_selectedPackage = _redirectedPackage;
			}
		}

		if(planConfig.sentry && (planConfig.freeSentry || queryParams?.antivirus === 'true')) {
			const _sentryProps = {
				[PlanTypes.YEARLY]: (!queryParams.plan || _selectedPackage.packageIdentifier === PlanTypes.YEARLY),
				[PlanTypes.YEARLY2]: (!queryParams.plan || _selectedPackage.packageIdentifier === PlanTypes.YEARLY2)
			};
			onUpdateSentryProps({
				...sentryProps,
				..._sentryProps
			});
		}
		onSetSelectedPackage(_selectedPackage);
	}

	handleSelectPackage(p) {
		this.props.onSetSelectedPackage(p);
	}

	handleScrollToPaymentSection(p) {
		if(p) {
			this.props.onSetSelectedPackage(p);
		}
		if(document.getElementById('paymentSection')) {
			scroller.scrollTo('paymentSection', {
				duration: 700,
				smooth: true
			});
		}
	}

	handleToggleFreemiumModal(showFreemiumModal) {
		this.setState({showFreemiumModal, showAbandonmentModal: false}, () => {
			document.body.classList.remove('overflow-hidden');
		});
	}

	handleChangeFreemiumInterstitialStep(freemiumInterstitialStep, coupon, plan) {
		if(freemiumInterstitialStep !== this.state.freemiumInterstitialStep) {
			scroll.scrollToTop({
				duration: 300,
				smooth: true
			});
		}
		this.setState({freemiumInterstitialStep}, () => {
			const {planConfig = {}, packagesList, sentryProps, onSetSelectedPackage} = this.props;
			const _yearly2Package = packagesList.filter(p => p.packageIdentifier === PlanTypes.YEARLY2)[0] || {};
			const _yearlyPackage = packagesList.filter(p => p.packageIdentifier === PlanTypes.YEARLY)[0] || {};
			let _selectedPackage = {};

			if(plan) {
				_selectedPackage = plan;
			} else if(freemiumInterstitialStep === 0) {
				_selectedPackage = planConfig.showYearly2 ? _yearly2Package : _yearlyPackage;
			} else {
				_selectedPackage = _yearlyPackage;
			}
			onSetSelectedPackage(_selectedPackage);
			
			if(coupon) {
				this.props.onGetCouponByCode(coupon, _selectedPackage.packageIdentifier, (sentryProps[_selectedPackage.packageIdentifier] ? ['SENTRYS'] : []));
			} else {
				this.props.onResetCouponByCode();
			}
		});
	}

	handleOpenAbandonmentModal() {
		this.setState({showAbandonmentModal: true}, () => {
			document.body.classList.add('overflow-hidden');
		});
	}

	handleCloseAbandonmentModal(e, plan) {
		e.stopPropagation();
		this.setState({showAbandonmentModal: false}, () => {
			document.body.classList.remove('overflow-hidden');
			if(plan) {
				if(this.props.V3) {
					this.handleChangeFreemiumInterstitialStep(0, null, plan);
				} else {
					this.props.onSetSelectedPackage(plan);
				}
				this.handleScrollToPaymentSection();
			}
		});
	}

	handleRefreshPage() {
		this.props.history.go(0);
	}

	handleClickBanner(p) {
		this.handleChangeFreemiumInterstitialStep(0);
		this.handleScrollToPaymentSection(p);
	}

	render() {
		const {
			V3,
			V23,
			t,
			navigate,
			classes,
			paymentJS,
			selectedPackage,
			packagesList,
			securityDetails: {current_currency, is_proxy_detected, is_blocked},
			queryParams,
			queryParams: { 
				current_plan, 
				active_vpn, 
				days_left_qty,
			},
			coupon,
			couponByCode,
			planConfig = {},
			enableAbandonmentModal,
			serversDetails,
			disableProxyPopup,
			upgradeMode,
			couponMonthly,
			loading,
			couponYearly2,
			sentryProps
		} = this.props;
		const {showAbandonmentModal, showFreemiumModal, freemiumInterstitialStep} = this.state;
		let _packagesList = packagesList.map(p => {
			return {
				...p,
				recurringPeriod: {
					...p.recurringPeriod,
					basePrice: [
						...p.recurringPeriod.basePrice
					]
				}

			};
		});
		
		if(coupon.code) {
			const couponYearlyValidPlan = coupon?.validPlans[0] || '';
			const couponYearlyPriceObj = coupon?.typeParameters?.fixedPrice || {};

			_packagesList =  _packagesList.map(p => {
				let _bp = p.recurringPeriod && p.recurringPeriod.basePrice;
				if(couponYearlyValidPlan ===  p.packageIdentifier && _bp.length) {
					p.recurringPeriod.basePrice = _bp.map(bp => ({...bp, amount: +(couponYearlyPriceObj[bp.currencyCode] || bp.amount)}));
					p.recurringPeriod.renewalPrice = +coupon.renewal_price || 0;
					if(coupon.grace_period_months) {
						p.recurringPeriod.grace_period_months = coupon.grace_period_months;
					}
				}
				return p;
			});
		}
		
		if(couponYearly2.code) {
			const couponYearly2ValidPlan = couponYearly2.validPlans && couponYearly2.validPlans[0] || '';
			const couponYearly2PriceObj = couponYearly2.typeParameters && couponYearly2.typeParameters.fixedPrice || {};

			_packagesList =  _packagesList.map(p => {
				let _bp = p.recurringPeriod && p.recurringPeriod.basePrice;
				if(couponYearly2ValidPlan ===  p.packageIdentifier && _bp.length) {
					p.recurringPeriod.basePrice = _bp.map(bp => ({...bp, amount: +(couponYearly2PriceObj[bp.currencyCode] || bp.amount)}));
					p.recurringPeriod.renewalPrice = +couponYearly2.renewal_price || 0;
					if(couponYearly2.grace_period_months) {
						p.recurringPeriod.grace_period_months = couponYearly2.grace_period_months;
					}
				}
				return p;
			});
		}

		if(couponMonthly.code) {
			const couponMonthlyValidPlan = couponMonthly.validPlans && couponMonthly.validPlans[0] || '';
			const couponMonthlyPriceObj = couponMonthly.typeParameters && couponMonthly.typeParameters.fixedPrice || {};

			_packagesList =  _packagesList.map(p => {
				let _bp = p.recurringPeriod && p.recurringPeriod.basePrice;
				if(couponMonthlyValidPlan ===  p.packageIdentifier && _bp.length) {
					p.recurringPeriod.basePrice = _bp.map(bp => ({...bp, amount: +(couponMonthlyPriceObj[bp.currencyCode] || bp.amount)}));
				}
				return p;
			});
		}

		const packages = _packagesList.filter(p => {
			return (p.packageIdentifier !== PlanTypes.OVERQUOTA 
				&& (p.packageIdentifier !== PlanTypes.YEARLY2 || p.packageIdentifier == PlanTypes.YEARLY2 && (planConfig.showYearly2 || upgradeMode)) 
				&& (((planConfig.hideFree && p.packageIdentifier !== PlanTypes.FREE) || !planConfig.hideFree) && ((planConfig.hideMonthly && p.packageIdentifier !== PlanTypes.MONTHLY) || !planConfig.hideMonthly)));
		});

		const monthlyPackage = _packagesList.find(p => p.packageIdentifier === PlanTypes.MONTHLY) || {};
		const monthlyPrice = monthlyPackage?.recurringPeriod?.basePrice.find(bp => bp.currencyCode === current_currency)?.amount || 0;
		const yearlyPackage = _packagesList.find(p => p.packageIdentifier === PlanTypes.YEARLY) || {};
		const yearlyPrice = yearlyPackage?.recurringPeriod?.basePrice.find(bp => bp.currencyCode === current_currency)?.amount || 0;
		const yearlyGracePeriod = yearlyPackage.recurringPeriod?.grace_period_months || 0;
		const yearly2Package = _packagesList.find(p => p.packageIdentifier === PlanTypes.YEARLY2) || {};
		const yearly2Price = yearly2Package?.recurringPeriod?.basePrice.find(bp => bp.currencyCode === current_currency)?.amount || 0;
		const yearly2GracePeriod = yearly2Package.recurringPeriod?.grace_period_months || 0;
		const couponValidPlan = couponByCode.validPlans && couponByCode.validPlans[0] || '';
		const couponGracePeriod = couponByCode.grace_period_months || 0;
		const couponPrice = couponByCode?.typeParameters?.fixedPrice && couponByCode?.typeParameters?.fixedPrice[current_currency] || '';
		const couponRenewalPrice = +(couponByCode.renewal_price || 0);
		const couponSavingPercent = couponByCode.discount_percent ? (couponByCode.discount_percent + '%') : Saving.percent;
		const transGetUnlimitedGrace = (CURRENCY[current_currency] || current_currency) + couponPrice;
		const pricePerMonth = (CURRENCY[current_currency] || current_currency) + utils.calculatePricePerMonth(((planConfig.showYearly2 || (upgradeMode && active_vpn)) ? yearly2Package : yearlyPackage), PlanTypes, current_currency, couponByCode);
		const saving = utils.calculateSaving((selectedPackage.packageIdentifier === PlanTypes.YEARLY2 ? yearly2Package : yearlyPackage), monthlyPrice, PlanTypes, current_currency, couponByCode, sentryProps, planConfig.freeSentry);
		const savingPercent = utils.calculateSaving(yearlyPackage, monthlyPrice, PlanTypes, current_currency, couponByCode, sentryProps, planConfig.freeSentry).savingPercent;
		const saving2Percent = utils.calculateSaving(yearly2Package, monthlyPrice, PlanTypes, current_currency, couponByCode, sentryProps, planConfig.freeSentry).savingPercent;
		const PlansSection = V3 ? PlansSectionV3 : PlansSectionV2;
		const PaymentSection = V3 || V23 ? PaymentSectionV3 : PaymentSectionV2;

		return (
			<>
				{loading ? <Loader /> : 
					is_blocked ? <Blocked /> :
						<div className={clsx('page-content', {'bg-grey v2': !V3 && !V23}, {'v23': V23})} id="page-content">
							{!planConfig.hideTopBanner && upgradeMode && (!active_vpn && current_plan == 'FREE') ?
								<div className={classes.topBanner} onClick={this.handleScrollToPaymentSection}>
									<Container component="div" className={classes.topBannerContainer}>
										<Typography component="p" className={classes.topBannerText}>
											{t((days_left_qty >= 0) ?
												'You have 0 GB of data remaining. To stay connected and secure, activate Limited Speed or upgrade to get unlimited data.' :
												'Your account is eligible for more monthly data. Refresh now for an extra 10 GB or upgrade to get unlimited VPN data and protection.'
											)}
										</Typography>
										<Typography component="div" className={classes.topBannerCTA}>
											{t('Upgrade Now')}
										</Typography>
									</Container>
								</div>: null
							}
							
							{planConfig.aimtellBanner ?
								<div className={classes.bannerWrapper}>
									<AimtellBanner 
										price={pricePerMonth} 
										planConfig={planConfig}
										onClickBanner={() => this.handleClickBanner(planConfig.showYearly2 ? yearly2Package : yearlyPackage)} />
								</div> : null
							}
							{planConfig.saleTimerBanner ?
								<div className={clsx({[classes.bannerWrapper]: V3})}>
									<SaleTimerBanner 
										price={pricePerMonth} 
										planConfig={planConfig}
										onClickBanner={() => this.handleScrollToPaymentSection((planConfig.showYearly2 || (upgradeMode && active_vpn)) ? yearly2Package : yearlyPackage)}  />
								</div> : null
							}

							{freemiumInterstitialStep === 2 ?
								<Container maxWidth="md" component="main">
									<Box className={clsx(classes.interstitialSteps, classes.flexBox)} p={2}  pt={4}>
										<Box className={clsx(classes.interstitialStep, classes.stepPast)} onClick={() => this.handleChangeFreemiumInterstitialStep(0)}><span className={classes.interstitialStepCheckGreenFilled}></span>Select Plan</Box>
										<Box className={clsx(classes.interstitialStepLine, classes.interstitialStepLineGreen)}></Box>
										<Box className={clsx(classes.interstitialStep, classes.stepPast)} onClick={() => this.handleChangeFreemiumInterstitialStep(1)}><span className={classes.interstitialStepCheckGreenFilled}></span>Additional Options</Box>
										<Box className={clsx(classes.interstitialStepLine, classes.interstitialStepLineGreen)}></Box>
										<Box className={clsx(classes.interstitialStep, classes.stepCurrent)}><span className={classes.interstitialStepCheckGreen}></span>Checkout</Box>
									</Box>
								</Container> : null
							}

							<Container maxWidth="md" component="main" className={clsx({[classes.pageWrapper]: V3 || V23}, {[classes.regularVersion]: !V3})} style={{display: (freemiumInterstitialStep === 1 ? 'none' : 'block')}}>
								<Container maxWidth="md" component="main" className={clsx({[classes.pageHeader]: !V3})}>
									<Typography component="h1" variant="h4" align={planConfig.title ? 'center' : 'left'} color="textPrimary" gutterBottom className={clsx({[classes.contentHeader]: V3})}>
										{planConfig.title ?
											<span dangerouslySetInnerHTML={{__html: utils.processTitle(t, current_currency, (planConfig.showYearly2 ? Math.round((yearly2Price/(yearly2GracePeriod || 24))*100)/100 : ((couponValidPlan === PlanTypes.YEARLY && couponPrice ? Math.round((couponPrice/(couponGracePeriod || 12))*100)/100 : Math.round((yearlyPrice/(yearlyGracePeriod || 12))*100)/100).toFixed(2))), planConfig.title)}}></span> :
											V3 ? t(freemiumInterstitialStep === 2 ? 'Select Plan' : 'Get PrivadoVPN') :
												couponGracePeriod ?
													<Trans i18nKey="transGetUnlimitedGrace" transGetUnlimitedGrace={transGetUnlimitedGrace}>
														Get Unlimited Data for only {{transGetUnlimitedGrace}}
													</Trans> :
													<Trans i18nKey="transGetUnlimitedDataForOnly">
														Get Unlimited Data for only {{transGetUnlimitedDataForOnly: pricePerMonth}}/mo
													</Trans>
										}
									</Typography>
									{V3 ? null : 
										planConfig.subTitle ?
											planConfig.subTitle.split('{br}').map((st, i) => {
												return (
													st.length ?
														<Typography key={i} component="h2" align="center" color="textPrimary" style={{maxWidth: (planConfig.subTitleWidth ? (planConfig.subTitleWidth + 'px') : '100%')}} gutterBottom>
															{utils.processTitle(t, current_currency, (planConfig.showYearly2 ? Math.round((yearly2Price/(yearly2GracePeriod || 24))*100)/100 : ((couponValidPlan === PlanTypes.YEARLY && couponPrice ? Math.round((couponPrice/(couponGracePeriod || 12))*100)/100 : Math.round((yearlyPrice/(yearlyGracePeriod || 12))*100)/100).toFixed(2))), st)}
														</Typography>: null
												);
											}) :
											!upgradeMode && !planConfig.hideSubTitle ?
												<Typography component="h2" align="center" color="textPrimary" gutterBottom>
													{t('PrivadoVPN offers a Zero-Log server network protected by Swiss-privacy laws.')}
												</Typography>: null
									}
									{planConfig.featuredOn ?
										<Typography component="div" align="center" color="textPrimary" className={classes.featuredOn}>
											<span>Featured on</span>
											<img src={vpnOverviewLogo} alt="logo" className={classes.vpnOverviewLogo} />
										</Typography>: null
									}
								</Container>

								<PlansSection
									V23={V23}
									navigate={navigate}
									upgradeMode={upgradeMode}
									planConfig={planConfig}
									savingPercent={savingPercent}
									saving2Percent={saving2Percent}
									couponPrice={couponPrice}
									couponGracePeriod={couponGracePeriod}
									couponValidPlan={couponValidPlan}
									couponSavingPercent={couponSavingPercent}
									couponRenewalPrice={couponRenewalPrice}
									queryParams={queryParams}
									packages={packages}
									monthlyPrice={monthlyPrice}
									yearlyPackage={yearlyPackage}
									yearly2Package={yearly2Package}
									currency={current_currency}
									selectedPackage={selectedPackage}
									serversDetails={serversDetails}
									showFreemiumModal={showFreemiumModal}
									freemiumInterstitialStep={freemiumInterstitialStep}
									onToggleFreemiumModal={this.handleToggleFreemiumModal}
									onChangeFreemiumInterstitialStep={this.handleChangeFreemiumInterstitialStep}
									handleSelectPackage={this.handleSelectPackage}
									handleScrollToPaymentSection={this.handleScrollToPaymentSection} />

								<PaymentSection
									upgradeMode={upgradeMode}
									navigate={navigate}
									planConfig={planConfig}
									packages={packages}
									savingPercent={savingPercent}
									saving2Percent={saving2Percent}
									coupon={couponByCode}
									couponPrice={couponPrice}
									couponGracePeriod={couponGracePeriod}
									couponValidPlan={couponValidPlan}
									couponSavingPercent={couponSavingPercent}
									couponRenewalPrice={couponRenewalPrice}
									queryParams={queryParams}
									currency={current_currency}
									paymentJS={paymentJS}
									saving={saving}
									selectedPackage={packages.find( p => p.packageIdentifier === selectedPackage.packageIdentifier) || {}}
									yearlyPackage={yearlyPackage}
									yearly2Package={yearly2Package}
									hideCouponSection={freemiumInterstitialStep === 2}
									handleSelectPackage={this.handleSelectPackage}
									enableAbandonmentModal={enableAbandonmentModal}
									onOpenAbandonmentModal={this.handleOpenAbandonmentModal}
									handleScrollToPaymentSection={this.handleScrollToPaymentSection}
									onChangeFreemiumInterstitialStep={this.handleChangeFreemiumInterstitialStep} />
							</Container>

							<Container maxWidth="md" component="main" style={{display: (freemiumInterstitialStep === 1 ? 'block' : 'none')}}>
								<Box className={clsx(classes.interstitialSteps, classes.flexBox)} p={2}  pt={4}>
									<Box className={clsx(classes.interstitialStep, classes.stepPast)} onClick={() => this.handleChangeFreemiumInterstitialStep(0)}><span className={classes.interstitialStepCheckGreenFilled}></span>Select Plan</Box>
									<Box className={clsx(classes.interstitialStepLine, classes.interstitialStepLineGreen)}></Box>
									<Box className={clsx(classes.interstitialStep, classes.stepCurrent)}><span className={classes.interstitialStepCheckGreen}></span>Additional Options</Box>
									<Box className={classes.interstitialStepLine}></Box>
									<Box className={clsx(classes.interstitialStep, classes.stepNext)}><span className={classes.interstitialStepCheckWhite}></span>Checkout</Box>
								</Box>
								<FreemiumInterstitialV3 
									serversDetails={serversDetails}
									currency={current_currency} 
									savingPercent={saving2Percent}
									price={Math.round((yearly2Price/(yearly2GracePeriod || 24))*100)/100}
									onChangeFreemiumInterstitialStep={this.handleChangeFreemiumInterstitialStep} />
								<Box className={clsx(classes.interstitialActions, classes.flexBox)} p={2} pb={4}>
									<Box className={clsx(classes.cursorPointer, classes.arrowLeft)} onClick={() => this.handleChangeFreemiumInterstitialStep(0)}>Back</Box>
									<Box className={clsx(classes.cursorPointer)} onClick={() => this.handleChangeFreemiumInterstitialStep(2, planConfig.couponInterstitial)}>Continue with Free</Box>
								</Box>
							</Container>

							{(disableProxyPopup || !is_proxy_detected) && !showFreemiumModal && enableAbandonmentModal ?
								<AbandonmentModal
									customTitle={planConfig.modalTitle}
									showYearly2={planConfig.showYearly2}
									showModal={showAbandonmentModal}
									pricePerMonth={pricePerMonth}
									couponPrice={couponPrice}
									couponGracePeriod={couponGracePeriod}
									couponSavingPercent={couponSavingPercent}
									savingPercent={savingPercent}
									saving2Percent={saving2Percent}
									yearlyPackage={yearlyPackage}
									yearly2Package={yearly2Package}
									onClose={this.handleCloseAbandonmentModal} /> : null
							}
							{!disableProxyPopup && is_proxy_detected ?
								<ProxyDetectModal
									onClick={this.handleRefreshPage} /> :
								null
							}
							
							{planConfig.cancelOfferMode && queryParams?.username ? 
								<Container maxWidth="md" component="main">
									<Box display="flex" justifyContent="center" className={classes.btnsContainer}>
										<a color="textPrimary" href={REACT_APP_URL + '/cancel/confirm'} rel="noopener noreferrer" className={classes.btnTransparent}>
											{t('Continue Cancellation')}
										</a>
										<a color="textPrimary" href={REACT_APP_URL + '/admin-panel'} rel="noopener noreferrer" className={classes.btnOrange}>
											{t('Keep My Account')}
										</a>
									</Box>
								</Container> : null
							}
						</div>
				}
				<span id="maskEmailField"></span>
			</>
		);
	}

}

const mapStateToProps = (state) => {
	const {couponIsLoading, couponYearly2IsLoading, couponMonthlyIsLoading, userAuthInfoLoading} = state.home;
	const {packagesIsLoading} = state.packages;
	const {checkingSecurity} = state.securityCheck;

	return {
		packagesList: state.packages.packagesList || [],
		selectedPackage: state.home.selectedPackage || {},
		securityDetails: state.securityCheck.securityDetails,
		coupon: state.home.coupon,
		couponByCode: state.home.couponByCode,
		serversDetails: state.home.serversDetails,
		couponMonthly: state.home.couponMonthly,
		couponYearly2: state.home.couponYearly2,
		sentryProps: state.home.sentryProps,
		loading: checkingSecurity || packagesIsLoading || couponIsLoading || couponYearly2IsLoading || couponMonthlyIsLoading || userAuthInfoLoading
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetCouponMonthly: (coupon, plan, addons) => dispatch(getCouponMonthly(coupon, plan, addons)),
		onGetCouponYearly2: (coupon, plan, addons) => dispatch(getCouponYearly2(coupon, plan, addons)),
		onGetCouponByCode: (coupon, plan, addons, email) => dispatch(getCouponByCode(coupon, plan, addons, email)),
		onResetCouponByCode: dataToSend => dispatch(resetCouponByCode(dataToSend)),
		onUpdateSentryProps: sentryProps => dispatch(updateSentryProps(sentryProps)),
	};
};

Home.propTypes = {
	sentryProps: PropTypes.object,
	V3: PropTypes.bool,
	V23: PropTypes.bool,
	upgradeMode: PropTypes.bool,
	history: PropTypes.object,
	coupon: PropTypes.object,
	couponMonthly: PropTypes.object,
	couponYearly2: PropTypes.object,
	couponByCode: PropTypes.object,
	serversDetails: PropTypes.object,
	planConfig: PropTypes.object,
	classes: PropTypes.object,
	queryParams: PropTypes.object,
	paymentJS: PropTypes.object,
	selectedPackage: PropTypes.object,
	securityDetails: PropTypes.object,
	packagesList: PropTypes.array,
	loading: PropTypes.bool,
	enableAbandonmentFreeModal: PropTypes.bool,
	enableAbandonmentModal: PropTypes.bool,
	disableProxyPopup: PropTypes.bool,
	onSetSelectedPackage: PropTypes.func,
	onGetCouponMonthly: PropTypes.func,
	onGetCouponYearly2: PropTypes.func,
	onGetCouponByCode: PropTypes.func,
	onResetCouponByCode: PropTypes.func,
	onUpdateSentryProps: PropTypes.func,
	t: PropTypes.func,
	navigate: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(withNamespaces()(Home)));