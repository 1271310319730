import iconMoneyBackWhite from '../../../assets/images/icons/icon-mbg-white.png';
import checkMarkGreen from '../../../assets/images/checked-green.png';
import deleteRed from '../../../assets/images/icon-delete-red2.png';
import exclamation from '../../../assets/images/icon-exclamation-mark.png';

export const useStyles = () => ({
	hide: {
		display: 'none !important',
	},
	textRed: {
		color: '#E52866',
	},
	pt10: {
		paddingTop: 10,
	},
	lineThrough: {
		textDecoration: 'line-through',
	},
	plansWrapper: {

	},
	packagesWrapper: {
		paddingBottom: 60,
		marginBottom: 0,
	},
	packageContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		maxWidth: '420px !important',
	},
	cardWrapper: {
		position: 'relative',
		margin: 4,
		padding: 8,
		borderRadius: 10,
		background: '#93999C',
		width: '100%',
		cursor: 'pointer',
	},
	card: {
		position: 'relative',
		zIndex: 100,
		overflow: 'visible !important',
		borderRadius: 6,
		boxShadow: 'none',
		justifyContent: 'flex-start',
		padding: 0,
		margin: 0,
	},
	bgGreyPackage: {
		'& $cardWrapper': {
			padding: 4,
			background: '#E9EAEB',
			'& $card': {
				background: '#E9EAEB',
			}
		}
	},
	cardHeaderBadge: {
		position: 'absolute',
		top: '-22px',
		left: 'calc(50% - 100px)',
		minWidth: '200px',
		textAlign: 'center',
		fontSize: 14,
		lineHeight: '24px',
		fontWeight: 700,
		fontFamily: 'Spartan, sans-serif',
		padding: '6px 10px',
		background: '#12AF5A',
		zIndex: 2,
		color: '#fff',
		letterSpacing: '0px',
		borderRadius: 30,
	},
	cardHeader: {
		padding: '46px 0 6px 0',
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		'& span': {
			color: '#6d7173',
			fontSize: 16,
			fontWeight: 700,
			fontFamily: 'Spartan, sans-serif',
		}
	},
	cardContent: {
		padding: '0px !important',
		borderBottomLeftRadius: 4,
		borderBottomRightRadius: 4,
	},
	cardUserPercentage: {
		left: 0,
		right: 0,
		bottom: '-44px',
		position: 'absolute',
		fontSize: '13px',
		fontWeight: 700,
		textAlign: 'center',
		color:'#1a235b',
		padding: 9,
		background: '#e1f8f1',
		borderRadius: '6px',
	},
	cardUserPercentageIcon: {
		transform: 'rotate(-81deg)',
		display: 'inline-block',
		fontSize: '12px !important',
	},
	planCircle: {
		position: 'absolute',
		top: 14,
		left: 14,
		width: 24,
		minWidth: 24,
		maxWidth: 24,
		height: 24,
		background: '#ffffff',
		border: '2px solid #d4d6d7',
		borderRadius: 12,
		display: 'block',
	},
	planPriceWrapper: {
		// marginLeft: '-16px',
		// marginRight: '-16px',
		// paddingLeft: 16,
		// paddingRight: 16,
		paddingBottom: 30,
	},
	planPrice: {
		fontSize: 44,
		color: '#525B60',
		fontWeight: 700,
		fontFamily: 'Spartan, sans-serif',
		textAlign: 'center',
		paddingTop: 10,
		paddingBottom: 10,
		'& $month': {
			fontSize: 14,
		},
	},
	planOldPrice: {
		fontSize: 14,
		color: '#A6A9AB',
		fontWeight: 600,
		fontFamily: 'Spartan, sans-serif',
		textAlign: 'center',
		'& p': {
			margin: '3px 0 0 0',
		}
	},
	planShowBill: {
		'& $planPrice': {
			paddingBottom: 0,
		},
		'& $cardHeader': {
			marginBottom: 10,
		}
	},
	planCustomTotalPrice: {
		fontSize: 16,
		color: '#31c261',
		fontWeight: 700,
	},
	planOldTotalPriceDeleted: {
		color: '#fc1028',
		textDecoration: 'line-through',
	},
	month: {

	},
	planButton: {
		background: '#D4D6D7',
		borderRadius: 30,
		textAlign: 'center',
		fontSize: 16,
		fontWeight: 700,
		color: '#ffffff',
		padding: '18px 10px',
		marginTop: 30,
	},
	planButtonUpgrade: {
		background: '#5058C8',
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	planButtonLoading: {
		background: '#D4D6D7',
		cursor: 'not-allowed',
	},
	planBottomInfo: {
		left: 0,
		right: 0,
		bottom: '-80px',
		position: 'absolute',
		fontSize: 13,
		fontWeight: 700,
		color:'#ffffff',
		padding: '15px 20px',
		backgroundColor: '#858ADA',
		borderRadius: 10,
		textAlign: 'center',
	},
	infoDanger: {
		background: '#E52866',
	},
	planBottomInfoDe: {
		bottom: '-100px',
	},
	planFooterFlagContainer: {
		padding: '16px 0 6px 0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	planFooterFlag: {
		position: 'relative',
		fontSize: 11,
		fontWeight: 700,
		lineHeight: '12px',
		color: '#ffffff',
		padding: '5px 15px 5px 30px',
		textAlign: 'center',
		'&::before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: '-2px',
			left: 0,
			width: 24,
			height: 24,
			backgroundSize: 'cover',
			backgroundImage: `url(${iconMoneyBackWhite})`,
		},
	},
	planFeatures: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	planFeatureContainer: {
		margin: '0px 10px 20px 10px',
	},
	planFeature: {
		position: 'relative',
		paddingLeft: 20,
		fontSize: 13,
		lineHeight: '28px',
		color: '#525B60',
		fontWeight: 700,
		fontFamily: 'Spartan, sans-serif',
		margin: 0,
		'&::before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: 5,
			left: 0,
			width: 16,
			height: 16,
			backgroundImage: `url(${checkMarkGreen})`,
			backgroundSize: '100%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		},
		'&$planFeaturesDeleteIcon': {
			'&::before': {
				content: '""',
				width: 14,
				height: 14,
				top: 5,
				backgroundImage: `url(${deleteRed})`,
			},
		},
		'&$planFeaturesExclamationIcon': {
			'&::before': {
				content: '""',
				width: 14,
				height: 14,
				top: 5,
				backgroundImage: `url(${exclamation})`,
			},
		},
	},
	planFeatureIcons: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		'& img': {
			marginLeft: 7,
		}
	},
	planFeaturesDeleteIcon: {},
	planFeaturesExclamationIcon: {},
	bestValuePackage: {
		'& $cardHeader': {
			paddingTop: 66,
		},
	},
	freePackage: {
		'& $planPriceWrapper': {

		},
	},
	activePackage: {
		'&$bgGreyPackage': {
			'& $cardWrapper': {
				padding: 4,
				background: '#767CD4',
				'& $card': {
					background: '#ffffff',
				}
			}
		},
		'& $cardWrapper': {
			background: 'linear-gradient(360deg, #5058C8 0%, #767CD4 100%)',
		},
		'& $cardHeader': {
			'& span': {
				color: '#283339',
			}
		},
		'& $planCircle': {
			background: 'transparent',
			border: '7px solid #767CD4',
		},
		'& $planPrice': {
			color: '#283339',
		},
		'& $planFeature': {
			color: '#283339',
		},
		'& $planButton': {
			background: '#FF8F00',
		},
		'& $planFooterFlag': {
			color: '#ffffff',
		},
	},
	upgradeMode: {
		'&$freePackage': {
			'& $card': {
				cursor: 'initial',
			}
		}
	},
	sentryContainer: {
		borderRadius: 4,
		background: '#F3F3FB',
		margin: '15px 15px -15px',
		'& label': {
			display: 'flex',
			alignItems: 'flex-start',
			justifyContent: 'center',
			padding: 10,
			margin: 0,
		}
	},
	sentryCheckboxLabelBox: {
		
	},
	sentryCheckboxLabel: {
		position: 'relative',
		display: 'flex',
		fontSize: 14,
		lineHeight: '24px',
		fontWeight: 700,
		textAlign: 'left',
	},
	sentryCheckbox: {
		margin: '-9px 0 -9px -9px',
	},
	'@media (max-width: 959px)': {
		planBottomInfo: {
			bottom: '-90px',
			'& br': {
				display: 'none',
			},
		},

		planBottomInfoDe: {
			bottom: '-100px',
		},
		cardHeader: {
			display: 'block',
			'&>div': {
				display: 'block',
			}
		},
		planPrice: {
			fontSize: 48,
		},
		bestValuePackage: {
			order: '0 !important',
			'& $planFeature': {
				marginBottom: 0,
			},
		},
		freePackage : {
			order: '10 !important',
		},
	},
	'@media (max-width: 460px)': {
		packagesWrapper: {
			paddingBottom: 55,
		},
		card: {
			padding: '50px 15px 30px',
		},
		bestValuePackage: {
			marginTop: 12,
		},
	}
});